import * as actionTypes from './types';
import axios from "axios";

const API = axios.create({
    baseURL: "https://scdn.monster/"
});

export const fetchConfig = () => dispatch => {
    API.get('/api/config').then(({data}) => {
        dispatch({
            type: actionTypes.FETCH_CONFIG_SUCCESS,
            payload: data
        })
    }).catch((e) => {
        dispatch({
            type: actionTypes.FETCH_CONFIG_ERROR,
            payload: e
        })
    })
};
