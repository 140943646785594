import * as actionTypes from './types';
import axios from 'axios';

const API = axios.create({
    baseURL: "https://scdn.monster/"
});

export const fetchGames = () => dispatch => {
    API.get('/api/sport/nfl').then(({data}) => {
        dispatch({
            type: actionTypes.FETCH_GAMES_SUCCESS,
            payload: data
        })
    }).catch((e) => {
        dispatch({
            type: actionTypes.FETCH_GAMES_ERROR,
            payload: e
        })
    })
};
